<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <select v-model="departement" class="w3-select w3-border w3-theme w3-round w3-small" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>
        <div class="w3-container w3-theme-l5 w3-round-large w3-padding scrlt-y" v-bind:style="'height:'+  (screenHeight-95) +'px;'">
            <div class="w3-row">
                <div class="w3-col l6">
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-green w3-padding">
                                <div class="w3-left"><i class="fa fa-users w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Online Supplier</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    {{card.suppon}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w3-col l6">
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-red w3-padding">
                                <div class="w3-left"><i class="fa fa-users w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Offline Supplier</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    {{card.suppoff}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w3-row">
                <div class="w3-col s12">
                    <span class="w3-btn w3-block w3-theme-l1 w3-left-align round-top-large w3-small">
                       FILTER &nbsp;<i class="fa fa-caret-down"></i> 
                    </span>
                    <div class="w3-animate-zoom w3-white">
                        <form class="w3-container" id="frmCust" v-on:submit.prevent="showSupp" autocomplete="off">
                            <div class="w3-row w3-small">
                                <div class="w3-col m3 w3-padding">
                                    <label>Customer</label>
                                    <select v-model="recSupp.status" class="w3-select w3-round-large w3-white" style="width: 100%;" required>
                                        <option value="" disabled selected>Pilih Status</option>
                                        <option value="2">On</option>
                                        <option value="1">Off > 3 Bulan</option>
                                        <option value="0">Off</option>
                                    </select>
                                </div>
                                <div class="w3-col m3 w3-padding">
                                    <label>Bulan</label>
                                    <select v-model="recSupp.bulan" class="w3-select w3-round-large w3-white" required>
                                        <option value="" disabled selected>Pilih Bulan</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                                <div class="w3-col m3 w3-padding">
                                    <label>Tahun</label>
                                    <input v-model="recSupp.tahun" class="w3-input w3-round-large w3-border-bottom" type="number" placeholder="Tahun" required>
                                </div>
                                <div class="w3-col m3 w3-center" style="padding-top: 34px;">
                                    <button type="submit" class="w3-btn w3-theme w3-round-large" form="frmCust"><i class="fa fa-search"></i>&nbsp; Show</button>
                                </div>  
                            </div>
                            <br>
                            <p>
                            </p>
                        </form>
                    </div>
                    <div class="scrl-x">
                        <table class="w3-table w3-striped w3-small w3-hoverable" id="recsupp">
                            <thead>
                                <tr class="w3-theme-l3">
                                    <th class="w3-border-right">Supplier ID</th>
                                    <th class="w3-border-right">Supplier Name</th>
                                    <th class="w3-border-right">Alamat</th>
                                    <th class="w3-border-right">Tlp</th>
                                    <th class="w3-border-right">Last Transaction</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "../chart/PieChart.js";
import moment from "moment";

// import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Supplier',
    components: {
        PieChart,
        // TableRatio
        // AgGridVue
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            card : {
                suppon : 0,
                suppoff : 0,
            },
            rectargetdtl: {},
            recCharts : [],
            chartData: {},
            chartOptions: {},
            chartLabel : [],
            lineData : {},
            lineOptions : {},
            LdtNow : [],
            LdtLast : [],
            LineLabel : [],
            departement : 'SUB',
            dept: [
                { text: 'SURABAYA', value: 'SUB' },
                { text: 'SEMARANG', value: 'SRG' }
            ],
            recsratiocust : [],
            recSupp : {
                status : '',
                bulan : '',
                tahun : ''
            },
            recsSupp : []
        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
        },
        LoadData(){
            let self = this;
            let param = {
                pfunction : 'statussupp',
                filter : 'supp',
                dept: 'ALL'
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.card.suppoff = response.data.rec[0].tc_nilai;
                self.card.suppon = response.data.rec[1].tc_nilai;
            });
        },
        showSupp(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("dashboard/ApiDboard.php",{
                pfunction : 'filtersupp',
                status : self.recSupp.status,
                bulan : self.recSupp.bulan,
                tahun : self.recSupp.tahun
            }).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.recsCust = response.data.rec;
                $('#recsupp').DataTable({
                    destroy: true,
                    responsive: true,      
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.recsCust,
                    columns: [
                        {"data": "SuppID"},
                        {"data": "SuppName"},
                        {"data": "Address"},
                        {"data": "Phones"},
                        {"data": "lastdt"}
                    ],
                    columnDefs: [
                    ],
                    dom: 'lfBrtip',
                    buttons: [
                        { 
                            extend: 'csvHtml5', text: 'Export',
                            className: 'w3-btn w3-blue w3-tiny w3-round',                        
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        setInit() {
            this.LoadData();
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
